<template>
  <v-navigation-drawer v-model="toggle" color="#00316b" fixed app dark height="100%">
    <v-toolbar text color="#fff">
      <router-link :to="{ name: 'home' }" style="display: flex; justify-content: center; align-items: center;">
        <img src="../../assets/NMCI_logo-20230208-100506.png" width="75em" />
        <v-spacer></v-spacer>
        <img style="margin-left: 0.6em;" src="../../assets/New Halpin Logo-20230208-100503.png" width="75em" />
      </router-link>
    </v-toolbar>
    <v-list>
      <v-list-item @click="changeRoute('home', 1)">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-title :class="[{ active: selectedIndex === 1 }, 'item-title']">Home</v-list-item-title>
      </v-list-item>
      <v-list-item @click="changeRoute('preguntas', 2)">
        <v-list-item-action>
          <v-icon>mdi-help</v-icon>
        </v-list-item-action>
        <v-list-item-title :class="[{ active: selectedIndex === 2 }, 'item-title']">Questions</v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('respuestas', 3)">
        <v-list-item-action>
          <v-icon>mdi-grease-pencil</v-icon>
        </v-list-item-action>
        <v-list-item-title :class="[{ active: selectedIndex === 3 }, 'item-title']">Answers</v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('formulario', 4)">
        <v-list-item-action>
          <v-icon>mdi-form-select</v-icon>
        </v-list-item-action>
        <v-list-item-title :class="[{ active: selectedIndex === 4 }, 'item-title']">Forms</v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('tipos', 5)">
        <v-list-item-action>
          <v-icon>mdi-format-list-bulleted-type</v-icon>
        </v-list-item-action>
        <v-list-item-title :class="[{ active: selectedIndex === 5 }, 'item-title']">Question type</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-sheet class="vsheet" color="#ccd8e3">
        <img class="logoAT_divided" src="../../assets/ATVirtual-logo-UP.png"/>
        <img class="logoAT_divided" src="../../assets/ATVirtual-logo-DOWN.png"/>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      selectedIndex: 1,
    };
  },

  methods: {
    changeRoute(routeName, selectedIndex) {
      const vm = this;
      vm.selectedIndex = selectedIndex;

      return vm.$router.push({ name: routeName });
    },
  },
};
</script>

<style>
.subItem {
  margin-left: 1em;
  margin-right: 1em;
}

.toolbar {
  font-weight: bold;
  font-size: 18px;
}

.toolbar .text {
  padding-left: 15px;
  color: white;
  text-decoration: none;
}

.item-title {
  font-size: 17px;
  font-weight: 500;
}
.item-sub-title {
  font-size: 15px;
  font-weight: 500;
}

.active {
  font-weight: bold;
}

div .vsheet{
  position: absolute;
  bottom: 0.1em;
  height: fit-content;
  width: fit-content;
}

</style>
