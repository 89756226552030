<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="80vh" persistent>
      <v-card class="logWrap">
        <v-card-title primary-title>
          <h3>Change password</h3>
          <br />
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="password"
            :append-icon="'mdi-lock'"
            rounded
            filled
            dense
            name="newPass"
            label="New Password"
            id="newPass"
            :rules="[rules.required]"
            v-model="newPass"
          />
          <v-text-field
            :type="'password'"
            :append-icon="'mdi-lock-alert'"
            rounded
            dense
            filled
            name="repeatPass"
            label="Repeat New Password"
            id="repeatPass"
            :rules="[
              rules.required,
              rules.passLength,
              rules.passCapitalLetter,
              rules.passLowCaseLetter,
              rules.passNumber,
              rules.passSpecialCharacter
            ]"
            v-model="repeatPass"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('confirmar')"
            class="white--text logWrap"
            color="#004283"
          >
            Confirm
          </v-btn>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Required field.",
        passLength: value =>
          (value.split("").length > 8 && value.split("").length < 17) ||
          "The length of the password must be between 9 and 16 characters (both included).",
        passCapitalLetter: value =>
          value.match(/.*[A-Z].*/) || "The password must contain at least one capital letter.",
        passLowCaseLetter: value =>
          value.match(/.*[a-z].*/) || "The password must contain at least one lowercase letter.",
        passNumber: value =>
          value.match(/.*[0-9].*/) || "The password must contain at least one number.",
        passSpecialCharacter: value =>
          "[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~]"
            .split("")
            .some(sc => value.includes(sc)) ||
            "The password must contain at least one special character."
      },
      newPass: "",
      repeatPass: "",
      hidePasswordNew: true,
      hidePasswordRepeat: true,
      user: this.$store.state.auth.user
    };
  },
  methods: {
    confirm(action) {
      if (action == "confirmar") {
        if (this.newPass == undefined || /^\s*$/.test(this.newPass)) {
          this.$emit("closeACP", "Blank required fields.");
        } else if (
          this.repeatPass == undefined ||
          /^\s*$/.test(this.repeatPass)
        ) {
          this.$emit("closeACP", "Blank required fields.");
        } else if (this.repeatPass != this.newPass) {
          this.$emit(
            "closeACP",
            "The 'New Password' and 'Repeat New Password' fields are not the same."
          );
        } else if (this.repeatPass.length <= 8) {
          this.$emit(
            "closeACP",
            "The length of the password must be between 9 and 16 characters (both included)."
          );
        } else if (this.repeatPass.length > 16) {
          this.$emit(
            "closeACP",
            "The length of the password must be between 9 and 16 characters (both included)."
          );
        } else if (!this.repeatPass.match(/.*[A-Z].*/)) {
          this.$emit(
            "closeACP",
            "The password must contain at least one capital letter."
          );
        } else if (!this.repeatPass.match(/.*[a-z].*/)) {
          this.$emit(
            "closeACP",
            "The password must contain at least one lowercase letter."
          );
        } else if (!this.repeatPass.match(/.*[0-9].*/)) {
          this.$emit(
            "closeACP",
            "The password must contain at least one number."
          );
        } else if (
          !"[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~]"
            .split("")
            .some(sc => this.repeatPass.includes(sc))
        ) {
          this.$emit(
            "closeACP",
            "The password must contain at least one special character."
          );
        } else {
          this.$emit("closeACP", action, {
            newPass: this.newPass
          });
        }
      } else {
        this.$emit("closeACP", action);
      }
    },
    resetValues() {
      this.newPass = "";
      this.repeatPass = "";
      this.hidePasswordNew = true;
      this.hidePasswordRepeat = true;
    }
  }
};
</script>

<style scoped>
.logWrap {
  border-radius: 2em;
}
</style>
