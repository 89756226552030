import Vue from "vue";
import App from "./App.vue";
//import appInfo from "./app-info";
import store from "./store";
//import VueEvents from 'vue-events'
import Vuetify from "vuetify";
import { router } from "./router";
import { baseApiUrl } from "./assets/linkTree";
import { setupComponents } from "./config/setup-components";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
//import 'font-awesome/css/font-awesome.css';
import axios from "axios";
import VueAxios from "vue-axios";
import { jsPDF } from "jspdf";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import './app.scss'



// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(jsPDF);
Vue.use(VueAxios, axios);

//Vue.use(VueEvents)
Vue.config.productionTip = false;
//Vue.prototype.$appInfo = appInfo;
Vue.use(Vuetify);

const opts = {};
Vue.use(opts);
Vue.use(Vuetify);
setupComponents(Vue);

new Vue({
  //  store,
  el: "#app",
  router,
  store,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$baseApiUrl = baseApiUrl;
