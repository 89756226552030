/* eslint-disable */

import axios from "axios";
import { baseApiUrl } from "../assets/linkTree";
class UserService {
  changePass(id, oldpass, newpass) {
    return axios
      .post(baseApiUrl + "/api/pass/" + id, {
        id: id,
        oldpassword: oldpass,
        newpassword: newpass,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  async changePassForgot(id, newpass) {
    var val = false;
    await axios
      .post(baseApiUrl + "/api/passForgot/" + id, {
        newpassword: newpass,
      })
      .then((response) => {
        val = true;
      })
      .catch(() => {
        val = false;
      });
    return val;
  }
  async forgotPassword(email) {
    return await axios.post(baseApiUrl + "/api/forgotPassword", {
      email: email,
    });
  }
  async isPINcorrect(email, code) {
    return await axios
      .post(baseApiUrl + "/api/code", {
        email: email,
        code: code,
      })
      .catch(() => {
        return {};
      });
  }
  getAll(id) {
    return axios.post(baseApiUrl + "/api/usersExceptMyself", { id: id });
  }
  getRoles() {
    return axios.get(baseApiUrl + "/api/roles");
  }
  add(item) {
    return axios.post(baseApiUrl + "/api/createAccount/", item);
  }
  update(id, item) {
    return axios.put(baseApiUrl + "/api/users/" + id, item);
  }
  delete(item) {
    return axios.delete(baseApiUrl + "/api/users/" + item.id);
  }
}

export default new UserService();
