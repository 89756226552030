<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="160vh" persistent>
      <v-card class="logWrap">
        <h1>{{ this.titulo }}</h1>
        <v-snackbar
          rounded
          :color="color"
          class="black--text"
          v-model="showResult"
          :timeout="2000"
          top
        >
          {{ result }}
        </v-snackbar>
        <v-data-table
          :headers="headers"
          :items="users"
          sort-by="nombre"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add user
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.username"
                            label="Username"
                            v-if="formTitle.includes('Edit')"
                            readonly
                            :rules="[rules.required, rules.empty]"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.username"
                            label="Username"
                            v-else
                            :rules="[rules.required, rules.empty]"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.name"
                            label="Name"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            :rules="[
                              rules.required,
                              rules.empty,
                              rules.validateEmail,
                            ]"
                          ></v-text-field>
                          <v-select
                            v-model="editedItem.role"
                            item-text="name"
                            item-value="id"
                            :items="roles"
                            persistent-hint
                            label="User Role"
                            :rules="[rules.required, rules.nulled]"
                          />
                          <v-text-field
                            :type="hidePassword ? 'password' : 'text'"
                            v-if="editedIndex < 0"
                            :append-icon="
                              hidePassword ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            label="Password"
                            v-model="editedItem.password"
                            @click:append="hidePassword = !hidePassword"
                            persistent-hint
                            :rules="[
                              rules.required,
                              rules.empty,
                              rules.passLength,
                              rules.passCapitalLetter,
                              rules.passLowCaseLetter,
                              rules.passNumber,
                              rules.passSpecialCharacter,
                            ]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete the user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import UserService from "@/services/UserService";
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
      showResult: false,
      result: "",
      color: "",
      dialog: false,
      dialogDelete: false,
      hidePassword: true,
      titulo: "User Management",
      headers: [
        {
          text: "Username",
          sortable: true,
          value: "username",
          align: "center",
        },
        {
          text: "Name",
          sortable: true,
          value: "name",
          align: "center",
        },
        {
          text: "Last name",
          sortable: true,
          value: "lastName",
          align: "center",
        },
        {
          text: "Email",
          sortable: true,
          value: "email",
          align: "center",
        },
        {
          text: "User role",
          sortable: true,
          value: "role.name",
          align: "center",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center",
        },
      ],
      users: [],
      roles: [],
      roleModel: {
        id: "",
        name: "",
      },
      roleTemplate: {
        id: "",
        name: "",
      },
      selectItems: [],
      editedItems: [],
      editedIndex: -1,
      itemBorrar: null,
      editedItem: {
        name: "",
        id: "",
        username: "",
        email: "",
        lastName: "",
        role: "",
        password: "",
      },
      defaultItem: {
        name: "",
        id: "",
        username: "",
        email: "",
        lastName: "",
        role: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || "Field required.",
        empty: (value) => value != "" || "Field required.",
        nulled: (value) => value != null || "Field required.",
        passLength: (value) =>
          (value.split("").length >= 8 && value.split("").length < 15) ||
          "The length of the password must be between 8 and 15 characters (both included)",
        passCapitalLetter: (value) =>
          value.match(/.*[A-Z].*/) ||
          "Password must contain at least one capital letter",
        passLowCaseLetter: (value) =>
          value.match(/.*[a-z].*/) ||
          "Password must contain at least one lowercase letter",
        passNumber: (value) =>
          value.match(/.*[0-9].*/) ||
          "Password must contain at least one number",
        passSpecialCharacter: (value) =>
          "[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~]"
            .split("")
            .some((sc) => value.includes(sc)) ||
          "Password must contain at least one special character",
        validateEmail: (value) =>
          value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) != null || "Email inválido",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add user" : "Edit user";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async validar() {
      this.valid = await this.$refs.form.validate();
      if (this.valid) return true;
      else return false;
    },
    async initialize() {
      this.users = [];
      var usersTemp = await UserService.getAll(this.$store.state.auth.user.id);
      for (var i = 0; i < usersTemp.data.length; i++) {
        this.users.push({
          name: usersTemp.data[i].name,
          id: usersTemp.data[i].id,
          username: usersTemp.data[i].username,
          email: usersTemp.data[i].email,
          lastName: usersTemp.data[i].lastName,
          role: usersTemp.data[i].role,
          id_role: usersTemp.data[i].role ? usersTemp.data[i].role.id : null,
        });
      }
      var rolesTemp = await UserService.getRoles();
      rolesTemp.data.forEach((r) => {
        this.roles.push(r);
      });
    },

    editItem(item) {
      this.roleModel = this.editedItem.role;
      this.editedIndex = this.users.indexOf(item);
      this.itemEditar = this.users.at(this.editedIndex);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.itemBorrar = this.users.at(this.editedIndex);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      var ej = await UserService.delete(this.itemBorrar);
      if (ej != undefined) {
        this.result = "Erase completed";
        this.color = "success";
        this.showResult = true;
      } else {
        this.result = "Erase failed";
        this.color = "error";
        this.showResult = true;
      }
      this.users.splice(this.editedIndex, 1);

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.roleModel = this.roleTemplate;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.roleModel = this.roleTemplate;
      });
    },

    async save() {
      var res = await this.validar();
      if (res) {
        if (this.editedIndex > -1) {
          //Cuando edito
          var item = {
            id: this.editedItem.id,
            name: this.editedItem.name,
            lastName: this.editedItem.lastName,
            username: this.editedItem.username,
            email: this.editedItem.email,
            id_role: this.editedItem.role,
          };
          var ej = await UserService.update(item.id, item);
          if (ej != undefined) {
            this.result = "Update completed";
            this.color = "success";
            this.showResult = true;
          } else {
            this.result = "Update failed";
            this.color = "error";
            this.showResult = true;
          }
        } else {
          //Cuando añado
          var item = {
            name: this.editedItem.name,
            lastName: this.editedItem.lastName,
            username: this.editedItem.username,
            email: this.editedItem.email,
            id_role: this.editedItem.role,
            password: this.editedItem.password,
          };
          var obj = await UserService.add(item);
          if (obj != undefined) {
            this.result = "Record added to Database";
            this.color = "success";
            this.showResult = true;
          } else {
            this.color = "error";
            this.result = "Database registration failure";
            this.showResult = true;
          }
        }
        this.initialize();
        this.close();
      }
    },
    confirm(action) {
      this.$emit("closeUS", action);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.logWrap {
  border-radius: 2em;
  padding: 1em;
}
</style>
