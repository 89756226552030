<template>
  <div id="app">
    <!-- v-if="!$route.meta.allowAnonymous" -->
    <template v-if="!$route.meta.allowAnonymous">
      <v-app id="inspire">
        <div class="app-container">
          <toolbar
            @toggleNavigationBar="drawer = !drawer"
            color="#b51845"
          />
          <navigation :toggle="drawer" />
          <v-main>
            <breadcrumbs />
            <router-view class="routerView" :key="$route.fullPath" />
            <page-footer />
          </v-main>
        </div>
      </v-app>
    </template>

    <template v-else>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
  
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.rowTitle {
  width: 100%;
}
.container {
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: darkgrey;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #004283;
}
h1 {
  text-align: center;
  color: #004283;
}

h3 {
  text-align: right;
  color: #004283;
}

.v-application--wrap {
  background-color: #f2f6fa;
  width: 95vh;
}
.v-toolbar__content {
  height: 100%;
}
.routerView {
  min-height: 90vh;
}
</style>
