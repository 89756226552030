var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('v-dialog',{attrs:{"value":_vm.active,"width":"80vh","persistent":""}},[_c('v-card',{staticClass:"logWrap"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',[_vm._v("Change password")]),_c('br')]),_c('v-card-text',[_c('v-text-field',{attrs:{"type":"password","append-icon":'mdi-lock',"rounded":"","filled":"","dense":"","name":"newPass","label":"New Password","id":"newPass","rules":[_vm.rules.required]},model:{value:(_vm.newPass),callback:function ($$v) {_vm.newPass=$$v},expression:"newPass"}}),_c('v-text-field',{attrs:{"type":'password',"append-icon":'mdi-lock-alert',"rounded":"","dense":"","filled":"","name":"repeatPass","label":"Repeat New Password","id":"repeatPass","rules":[
            _vm.rules.required,
            _vm.rules.passLength,
            _vm.rules.passCapitalLetter,
            _vm.rules.passLowCaseLetter,
            _vm.rules.passNumber,
            _vm.rules.passSpecialCharacter
          ]},model:{value:(_vm.repeatPass),callback:function ($$v) {_vm.repeatPass=$$v},expression:"repeatPass"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text logWrap",attrs:{"color":"#004283"},on:{"click":function($event){return _vm.confirm('confirmar')}}},[_vm._v(" Confirm ")]),_c('v-btn',{staticClass:"white--text logWrap",attrs:{"color":"#004283"},on:{"click":function($event){return _vm.confirm('cerrar')}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }