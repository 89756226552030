// Core Components
import Toolbar from "../components/core/Toolbar.vue";
import Navigation from "../components/core/NavigationDrawer.vue";
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
import PageFooter from "../components/core/PageFooter.vue";

//Modal Components
import ModalForgetPass from '../components/modals/ModalForgetPass.vue';
import ModalPersonalData from '../components/modals/ModalPersonalData.vue';
import ModalChangePass from "../components/modals/ModalChangePass.vue";
import ModalUsers from "../components/modals/ModalUsers.vue";
import ModalAuthChangePass from '../components/modals/ModalAuthChangePass.vue';
import ModalAuthPIN from '../components/modals/ModalAuthPIN.vue';

//QR Code
import VueQrcodeReader from "vue-qrcode-reader";
import VueQRCodeComponent from "vue-qr-generator";

function setupComponents(Vue) {
  Vue.component("toolbar", Toolbar);
  Vue.component("navigation", Navigation);
  Vue.component("breadcrumbs", Breadcrumbs);
  Vue.component("page-footer", PageFooter);

  Vue.use(VueQrcodeReader);
  Vue.component("qr-code", VueQRCodeComponent);

  Vue.component("modal-forget-pass", ModalForgetPass);
  Vue.component("modal-personal-data", ModalPersonalData);
  Vue.component("modal-change-pass", ModalChangePass);
  Vue.component("modal-users", ModalUsers);
  Vue.component('modal-auth-pin', ModalAuthPIN)
  Vue.component('modal-auth-change-pass', ModalAuthChangePass)
}

export { setupComponents };
