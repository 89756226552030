import axios from "axios";
import apiConfig from "./api.config";
import { baseApiUrl } from "../assets/linkTree";
const API_URL = process.env.baseURL || apiConfig.baseURL;

class AuthService {
  login(user) {
    return (
      axios.post(baseApiUrl + "/api/logIn", {
          username: user.username,
          password: user.password,
        })
        .then((response) => {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }

          return response.data;
        })
    );
  }
  logout() {
    sessionStorage.clear();
    localStorage.removeItem("user");
  }
  update(obj){
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(obj));
  }
}

export default new AuthService();
