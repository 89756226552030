import authHeader from './auth-header';
import { baseApiUrl } from '@/assets/linkTree';

export default {
    baseURL: baseApiUrl + '/api',
    timeout: 1000,

    connData(extraConfig) {
        return {... {
                baseURL: process.env.baseURL || this.baseURL,
                headers: authHeader(),
            },
            ...extraConfig
        }
    }
};