<template>
  <v-footer bottom style="width: 100%;" padless>
    <v-card text tile color="blue darken-4" class="indigo text-center" width="100%">
      <v-card-text>
        <v-btn
          class="mx-3 white--text"
          icon
          href="https://es-la.facebook.com/pages/category/Information-Technology-Company/Upintelligence-206257390288910/"
          target="_blank"
        >
          <v-icon size="24px">{{ icons[0] }}</v-icon>
        </v-btn>
        <v-btn
          class="mx-3 white--text"
          icon
          href="https://mobile.twitter.com/upintelligence"
          target="_blank"
        >
          <v-icon size="24px">{{ icons[1] }}</v-icon>
        </v-btn>
        <v-btn
          class="mx-3 white--text"
          icon
          href="https://es.linkedin.com/company/upintelligence"
          target="_blank"
        >
          <v-icon size="24px">{{ icons[2] }}</v-icon>
        </v-btn>
        <v-btn
          class="mx-3 white--text"
          icon
          href="https://www.instagram.com/upintelligence/?hl=es"
          target="_blank"
        >
          <v-icon size="24px">{{ icons[3] }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Software realizado por UPintelligence. UPintelligence está calificada
        como EIBT (Empresa Innovadora de Base Tecnológica) basada en la
        tecnología 5.0 y especializada en la transformación digital a través de
        Inteligencia Artificial y Realidad Extendida.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &copy;2021 — <strong>UPintelligence</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        "fab fa-facebook",
        "fab fa-twitter",
        "fab fa-linkedin",
        "fab fa-instagram"
      ],
      urls: [
        "https://es-la.facebook.com/pages/category/Information-Technology-Company/Upintelligence-206257390288910/",
        "https://mobile.twitter.com/upintelligence",
        "https://es.linkedin.com/company/upintelligence",
        "https://www.instagram.com/upintelligence/?hl=es"
      ]
    };
  }
};
</script>
