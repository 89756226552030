<template>
  <v-app-bar dark app color="#00316b">
    <v-app-bar-title>
      <v-app-bar-nav-icon @click="toggleNavigationBar"
        ><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon
      >
    </v-app-bar-title>
    <v-spacer></v-spacer>

    <v-menu
      class="toolbar-menu-item"
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon large text v-on="on" :ripple="false">
          <v-icon size="42">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in listItems"
          :key="index"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          @click="item.click"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <modal-personal-data
      v-if="this.isModalPDVisible"
      :active="this.isModalPDVisible"
      @closePD="actionsModalPersonalData"
      :key="this.PDKey"
    ></modal-personal-data>
    <modal-change-pass
      v-if="this.isModalChangePassVisible"
      :active="this.isModalChangePassVisible"
      @closeCP="actionsModalChangePass"
      :key="this.PassKey"
    >
    </modal-change-pass>
    <modal-users
      v-if="this.isModalUsersVisible"
      :active="this.isModalUsersVisible"
      @closeUS="actionsModalUsers"
      :key="this.GestUserKey"
    ></modal-users>
    <v-snackbar
      rounded
      :color="color"
      class="black--text"
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-app-bar>
</template>
<script>
import UserService from "@/services/UserService";
export default {
  mounted() {
    if (this.currentUser == null) {
      const vm = this;
      vm.$store.dispatch("auth/logout");
      vm.$router.push("/");
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    listItems() {
      if (this.currentUser!=null && this.currentUser.id_role == 1) {
        return this.items;
      } else if (this.currentUser !=null && this.currentUser.id_role != 1) {
        return this.itemsIsNotAdmin;
      }else{
        return []
      }
    },
  },
  data() {
    return {
      isModalPDVisible: false,
      PDKey: 0,
      isModalChangePassVisible: false,
      PassKey: 0,
      isModalUsersVisible: false, //TODO
      GestUserKey: 0,
      dialogSettings: false,
      error: false,
      showResult: false,
      result: "",
      color: "",
      items: [
        {
          icon: "mdi-account-edit",
          href: "#",
          title: "Personal Data",
          click: () => {
            this.isModalPDVisible = true;
          },
        },
        {
          icon: "mdi-account-lock",
          href: "#",
          title: "Change Password",
          click: () => {
            this.isModalChangePassVisible = true;
          },
        },
        {
          icon: "mdi-account-group",
          href: "#",
          title: "User Management",
          click: () => {
            this.isModalUsersVisible = true;
          },
        },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Logout",
          click: () => {
            const vm = this;
            vm.$store.dispatch("auth/logout");
            vm.$router.push("/");
          },
        },
      ],
      itemsIsNotAdmin: [
        {
          icon: "mdi-account-edit",
          href: "#",
          title: "Personal Data",
          click: () => {
            this.isModalPDVisible = true;
          },
        },
        {
          icon: "mdi-account-lock",
          href: "#",
          title: "Change Password",
          click: () => {
            this.isModalChangePassVisible = true;
          },
        },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Logout",
          click: () => {
            const vm = this;
            vm.$store.dispatch("auth/logout");
            vm.$router.push("/");
          },
        },
      ],
    };
  },
  methods: {
    actionsModalPersonalData: async function (action, obj) {
      if (action == "confirmar") {
        try {
          await UserService.update(obj.id, obj);
          await this.$store.dispatch("auth/update", obj);
          this.error = false;
          this.result = "Data succesfully updated";
          this.color = "success";
          this.showResult = true;
          this.isModalPDVisible = false;
          this.PDKey += 1;
        } catch (e) {
          this.error = true;
          this.result = "Fail on the update";
          this.color = "error";
          this.showResult = true;
          this.isModalPDVisible = true;
        }
      } else if (action == "cerrar") {
        this.isModalPDVisible = false;
      } else {
        this.error = true;
        this.color = "warning";
        this.result = action;
        this.showResult = true;
      }
    },
    actionsModalUsers: async function (action) {
      this.isModalUsersVisible = false;
      this.GestUserKey += 1;
    },
    actionsModalChangePass: async function (action, data) {
      if (action == "confirmar") {
        try {
          await UserService.changePass(data.id, data.oldPass, data.newPass);
          this.error = false;
          this.result = "Password updated";
          this.color = "success";
          this.showResult = true;
          this.isModalChangePassVisible = false;
          this.PassKey += 1;
        } catch (e) {
          this.error = true;
          this.result = "Fail on the update";
          this.color = "error";
          this.showResult = true;
          this.isModalChangePassVisible = true;
        }
      } else if (action == "cerrar") {
        this.isModalChangePassVisible = false;
      } else {
        this.error = true;
        this.color = "warning";
        this.result = action;
        this.showResult = true;
      }
    },
    toggleNavigationBar() {
      const vm = this;
      vm.$emit("toggleNavigationBar");
    },
  },
};
</script>
<style>
.toolbar-menu-item {
  padding-left: 5px;
}

.selected-language-flag {
  max-width: 45px;
}

.language-flag {
  max-width: 40px;
}

.languages-list-item {
  cursor: pointer;
  margin-top: -2px;
  margin-left: 1px;
}

.languages-list-item-title {
  font-size: 16px;
}

.languages-list-item-title:hover {
  color: #416bb8;
  font-weight: bold;
}
.language-menu {
  border-radius: 25px;
  width: 235px;
  margin-right: 10px;
}
</style>
