import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
let expiryDate =
  !!user && user.expiryDate ? new Date(user.expiryDate) : new Date();

const initialState =
  expiryDate > new Date()
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, obj) {
      AuthService.update(obj)
      commit("updateSuccess", obj)
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    updateSuccess(state, obj) {
      state.user = obj;
    },
  },
};

//TODO: test and evaluate error handling Headers...
