import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export const router = new Router({
  routes: [
    {
      path: "/home",
      name: "home",
      component: Vue.component("home", () => import("../pages/Home.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        breadcrumb: [{ href: "home" }],
      },
    },
    {
      path: "/preguntas",
      name: "preguntas",
      component: Vue.component("preguntas", () => import("../pages/Preguntas.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        breadcrumb: [{ href: "preguntas" }],
      },
    },
    {
      path: "/respuestas",
      name: "respuestas",
      component: Vue.component("respuestas", () => import("../pages/Answers.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        breadcrumb: [{ href: "respuestas" }],
      },
    },
    {
      path: "/tipos",
      name: "tipos",
      component: Vue.component("tipos", () => import("../pages/Tipo.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        breadcrumb: [{ href: "tipos" }],
      },
    },
    {
      path: "/formulario",
      name: "formulario",
      component: Vue.component("formulario", () => import("../pages/Formulario.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        breadcrumb: [{ href: "formulario" }],
      },
    },
    {
      path: "/login",
      name: "login",
      component: Vue.component("Login", () => import("../pages/core/Login.vue")),
      loading: Vue.component("Error", () => import("../pages/core/Error.vue")),
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: "/*",
      redirect: "/login",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/#/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  const userData = JSON.parse(loggedIn);
  let expiryDate = !!userData && userData.expiryDate ? new Date(userData.expiryDate) : new Date();
  if (authRequired && expiryDate < new Date()) {
    next("/login");
  } else {
    next();
  }
});