<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="80vh" persistent>
      <v-card class="logWrap">
        <v-card-title primary-title>
          <h3>Recover password</h3>
        </v-card-title>
        <v-card-text>
          <p>
            An email will be sent to the email provided to proceed with the
            password change.
          </p>
          <v-text-field
            filled
            rounded
            dense
            append-icon="mdi-at"
            name="login"
            label="Email"
            type="text"
            v-model="email"
            :rules="[rules.required, rules.validateEmail]"
            persistent-hint
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('confirmar')"
            class="white--text logWrap"
            color="#004283"
          >
            Continue
          </v-btn>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Field required.",
        validateEmail: (value) =>
          value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) != null || "Invalid email",
      },
      email: "",
    };
  },
  methods: {
    confirm(action) {
      if (action == "confirmar") {
        if (this.email == undefined || /^\s*$/.test(this.email)) {
          this.$emit("closeFP", "Field is empty", null);
        } else if (
          this.email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) == null
        ) {
          this.$emit("closeFP", "Invalid email", null);
        } else {
          var aux = this.email;
          this.email = "";
          this.$emit("closeFP", "confirmar", aux);
        }
      } else {
        this.email = "";
        this.$emit("closeFP", action, null);
      }
    },
  },
};
</script>

<style scoped>
.logWrap {
  border-radius: 2em;
}
</style>
