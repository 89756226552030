<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="80vh" persistent>
      <v-card class="logWrap">
        <v-card-title primary-title class="logWrap">
          <h3>Personal Data</h3>
        </v-card-title>
        <v-card-text class="logWrap">
          <v-text-field
            filled
            rounded
            dense
            readonly
            append-icon="mdi-account-details"
            name="username"
            label="Username"
            type="text"
            v-model="username"
            :rules="[rules.required]"
            persistent-hint
          />
          <v-text-field
            filled
            rounded
            dense
            append-icon="mdi-account-circle"
            name="name"
            label="Name"
            type="text"
            v-model="name"
            :rules="[rules.required]"
            persistent-hint
          />
          <v-text-field
            filled
            rounded
            dense
            append-icon="mdi-at"
            name="email"
            label="Email"
            type="text"
            v-model="email"
            :rules="[rules.required, rules.validateEmail]"
            persistent-hint
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('confirmar')"
            class="white--text logWrap"
            color="#004283"
          >
            Save
          </v-btn>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Field required.",
        validateEmail: (value) =>
          value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) != null || "Invalid field 'Email'",
      },
      email: "",
      name: "",
      username: "",
      rol: "",
      user: {},
    };
  },
  methods: {
    confirm(action) {
      if (action == "confirmar") {
        if (this.name == undefined || /^\s*$/.test(this.name)) {
          this.$emit("closePD", "Field 'Name' is empty");
        } else if (this.username == undefined || /^\s*$/.test(this.username)) {
          this.$emit("closePD", "Field 'Username' is empty");
        } else if (this.email == undefined || this.email.match(/^\s*$/)) {
          this.$emit("closePD", "Field 'Email' is empty");
        } else if (
          this.email != undefined &&
          this.email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) == null
        ) {
          this.$emit("closePD", "Invalid field 'Email'");
        } else {
          var json = {
            id: this.user.id,
            name: this.name,
            username: this.username,
            email: this.email,
            accessToken: this.user.accessToken,
            expiryDate: this.user.expiryDate,
            id_role: this.user.id_role,
            password: this.user.password,
          };
          this.$emit("closePD", action, json);
        }
      } else {
        this.$emit("closePD", action);
      }
    },
    setData() {
      this.user = this.$store.state.auth.user
      this.email = this.user.email;
      this.name = this.user.name;
      this.username = this.user.username;
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style scoped>
.logWrap {
  border-radius: 2em;
}
</style>
