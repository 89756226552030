<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="80vh" persistent>
      <v-card class="logWrap">
        <v-card-title primary-title>
          <h3>Change Password</h3>
          <br />
        </v-card-title>
        <v-card-text>
          <v-text-field
            :type="hidePasswordOld ? 'password' : 'text'"
            :append-icon="hidePasswordOld ? 'mdi-eye-off' : 'mdi-eye'"
            rounded
            dense
            filled
            name="oldPass"
            label="Current Password"
            id="oldPass"
            :rules="[rules.required]"
            v-model="oldPass"
            @click:append="hidePasswordOld = !hidePasswordOld"
          />
          <v-text-field
            :type="hidePasswordNew ? 'password' : 'text'"
            :append-icon="hidePasswordNew ? 'mdi-eye-off' : 'mdi-eye'"
            rounded
            filled
            dense
            name="newPass"
            label="New Password"
            id="newPass"
            :rules="[rules.required]"
            v-model="newPass"
            @click:append="hidePasswordNew = !hidePasswordNew"
          />
          <v-text-field
            :type="hidePasswordRepeat ? 'password' : 'text'"
            :append-icon="hidePasswordRepeat ? 'mdi-eye-off' : 'mdi-eye'"
            rounded
            dense
            filled
            name="repeatPass"
            label="Repeat New Password"
            id="repeatPass"
            :rules="[rules.required, rules.passLength, rules.passCapitalLetter, rules.passLowCaseLetter, rules.passNumber, rules.passSpecialCharacter]"
            v-model="repeatPass"
            @click:append="hidePasswordRepeat = !hidePasswordRepeat"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('confirmar')"
            class="white--text logWrap"
            color="#004283"
          >
            Save
          </v-btn>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Field required.",
        passLength: value =>
          (value.split("").length >= 8 && value.split("").length < 15) ||
          "The length of the password must be between 8 and 15 characters (both included)",
        passCapitalLetter: value => value.match(/.*[A-Z].*/) || "Password must contain at least one capital letter",
        passLowCaseLetter: value => value.match(/.*[a-z].*/) || "Password must contain at least one lowercase letter",
        passNumber: value => value.match(/.*[0-9].*/) || "Password must contain at least one number",
        passSpecialCharacter: value => ('[`!@#$%^&*()_+-=[]{};\':"\\|,.<>/?~]').split('').some(sc => value.includes(sc)) || "Password must contain at least one special character"
      },
      oldPass: "",
      newPass: "",
      repeatPass: "",
      hidePasswordOld: true,
      hidePasswordNew: true,
      hidePasswordRepeat: true,
      user: this.$store.state.auth.user
    };
  },
  methods: {
    confirm(action) {
      if (action == "confirmar") {
        if (this.oldPass == undefined || /^\s*$/.test(this.oldPass)) {
          this.$emit("closeCP", "Field 'Current Password' is empty");
        } else if (this.newPass == undefined || /^\s*$/.test(this.newPass)) {
          this.$emit("closeCP", "Field 'New Password' is empty");
        } else if (
          this.repeatPass == undefined ||
          /^\s*$/.test(this.repeatPass)
        ) {
          this.$emit("closeCP", "Field 'Repeat New Password' is empty");
        } else if (this.repeatPass != this.newPass) {
          this.$emit(
            "closeCP",
            "Los fields 'New Password' y 'Repeat New Password' aren not the same"
          );
        } else if (this.repeatPass.length < 8) {
          this.$emit(
            "closeCP",
            "The length of the password must be 8 or more characters"
          );
        } else if (this.repeatPass.length > 15) {
          this.$emit(
            "closeCP",
            "The length of the password must be 15 or less characters"
          );
        } else if (!this.repeatPass.match(/.*[A-Z].*/)) {
          this.$emit(
            "closeCP",
            "Password must contain at least one capital letter"
          );
        } else if (!this.repeatPass.match(/.*[a-z].*/)) {
          this.$emit(
            "closeCP",
            "Password must contain at least one lowercase letter"
          );
        }
        else if (!this.repeatPass.match(/.*[0-9].*/)) {
          this.$emit(
            "closeCP",
            "Password must contain at least one number"
          );
        } else if(!('[`!@#$%^&*()_+-=[]{};\':"\\|,.<>/?~]').split('').some(sc => this.repeatPass.includes(sc)) ){
          this.$emit(
            "closeCP",
            "Password must contain at least one special character"
          );
        }else {
          this.$emit("closeCP", action, {
            id: this.user.id,
            oldPass: this.oldPass,
            newPass: this.newPass
          });
        }
      } else {
        this.$emit("closeCP", action);
      }
    },
    resetValues() {
      this.oldPass = "";
      this.newPass = "";
      this.repeatPass = "";
      this.hidePasswordNew = true;
      this.hidePasswordOld = true;
      this.hidePasswordRepeat = true;
    }
  }
};
</script>

<style scoped>
.logWrap {
  border-radius: 2em;
}
</style>
