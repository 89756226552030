<template>
  <transition name="modal-fade">
    <v-dialog :value="active" width="35%" persistent>
      <v-card class="input-wrapper">
        <v-card-title primary-title>
          <h3>Insert PIN</h3>
          <br />
        </v-card-title>
        <v-card-text>
            <div class="pin-container">
              <PincodeInput v-model="code" placeholder="0" :length="6" />
            </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirm('confirmar')"
            class="white--text logWrap"
            color="#004283"
            :disabled="this.code.length!=6"
          >
            Continue
          </v-btn>
          <v-btn
            @click="confirm('cerrar')"
            class="white--text logWrap"
            color="#004283"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import PincodeInput from "vue-pincode-input";
import UserService from "@/services/UserService";
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      code: "",
    };
  },
  components: {
    PincodeInput
  },
  methods: {
    async confirm(action) {
      if (action == "confirmar") {
        var aux = this.code
        var obj = await UserService.isPINcorrect(this.email, aux)
        if(obj.data && obj.data.id){
          this.code = ''
          this.$emit("closePIN", action, true);
        }else{
          this.$emit("closePIN", action, false);
        }
      } else {
        this.$emit("closePIN", action, false);
        this.code = ''
      }
    },
  },
};
</script>

<style scoped>
.pin-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
